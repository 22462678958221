<template>
  <v-container grid-list-xl fluid>
    <v-layout row wrap>
      <v-flex sm6>
        <div class='headline green--text text--darken-4 font-weight-medium'>
          Settings
        </div>
      </v-flex>
      <v-flex lg12 sm12 xs12 v-if='allowReset'>
        <v-card>
          <v-toolbar card dense prominent color='primary darken-1' dark>
            <v-toolbar-title class='body-2'>Reset to default</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <div class='subheading font-weight-regular'>
              If you click this, it will reset all settings back to default.
            </div>
            <br />
            <v-btn outline :loading='submitting' @click='resetSettings'>
              Reset
            </v-btn>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import WebH from '../../api/web';
export default {
  created() {
    this.settings = this.$store.getters['SettingsStore/settings'];
    this.searchPatient();
  },
  data() {
    return {
      submitting: false,
      // questionGroups: [],
      // qGrpSelected: null,
      // fGrpSelected: null,
      // hGrpSelected:null,
      // eGrpSelected:null,
      // dailyAppointmentLimit: {
      //   Session1: 0,
      //   Session2: 0
      // },
      prefix: '',
      settings: []
    };
  },
  computed: {
    allowReset() {
      return this.$store.getters['UserStore/permissions'].includes('set.r');
    },
    allowEdit() {
      return this.$store.getters['UserStore/permissions'].includes('set.e');
    }
  },
  methods: {
    async loadSettings() {
      // await this.api_GetQuestionGroups();
      // this.loadQuestionSetting('complaint_set');
      // this.loadQuestionSetting('feedback_set');
      // this.loadQuestionSetting('exam_set');
      // this.loadQuestionSetting('habit_set');
      // this.loadDailyLimitSetting();
      this.loadPatientPrefix();
    },
    loadPatientPrefix() {
      const setting = this.settings.find(x => x.Name === 'patient_id_prefix');
      if (!setting) {
        return;
      }
      const val = JSON.parse(setting.JsonValue);
      this.prefix = val.Val;
    },
    async resetSettings() {
      const res = await this.$notifyDlg.show(
        'Do you wish to reset all settings?',
        'Warning',
        'warning',
        true
      );
      if (!res) return;
      await this.api_ResetSettings();
    },
    async api_ResetSettings() {
      this.submitting = true;
      const res = await WebH.Put('/settings/reset');
      if (res.Success) {
        this.$notify({
          type: 'info',
          text: 'settings has been reset to default'
        });
        this.settings = res.Data;
        await this.$store.dispatch('SettingsStore/setSettings', res.Data);
        // this.qGrpSelected = null;
        // this.fGrpSelected = null;
      } else {
        this.$notifyDlg.show(res.Data, 'Error', 'error');
      }
      this.submitting = false;
    }
  }
};
</script>

<style scoped></style>
